<template>
    <div class="popup_auth" v-show="showContent">
        <div class="popup_auth_form">
            <div class="popup_auth_formCloseBtn" @click.stop="closeForm()"></div>
            <div v-if="page == 1">
                <div class="popup_auth_form_title">Введите номер<br/>телефона, на который<br/>отправить код</div>
                <div class="popup_auth_body">
                    <div class="form_label">Телефон</div>
                    <input class="field_input" 
                           placeholder="+7 (___)-___-__-__" 
                           v-model="phone"
                           @keyup="testPhone"
                           type="tel"
                           v-mask="'+7(###) ### ## ##'" />
                    <div class="btn" 
                         :class="{'btn_active': okPhone}"
                         @click.stop="okPhone && showPage(2)">
                        Получить код
                    </div>
                </div>
            </div>
            <div v-if="page == 2">
                <div class="popup_auth_form_title">Подтвердите номер</div>
                <div class="popup_auth_body">
                    <div id="popup_auth_form_title_2">
                        На номер {{ phone }} ушло смс с кодом подтверждения. Введите его здесь:
                    </div>
                    <div id="form_smsCode">
                        <input placeholder="·" 
                               maxlength="1"
                               type="tel"
                               ref="sms_code_1"
                               :class="{'sms_digit_error': code.error}"
                               @keyup="nextCodeDigit(2); testSmsCode()"
                               v-model="code.code_1" />
                        <input placeholder="·" 
                               maxlength="1"
                               type="tel"
                               ref="sms_code_2"
                               :class="{'sms_digit_error': code.error}"
                               @keyup="nextCodeDigit(3); testSmsCode()"
                               v-model="code.code_2" />
                        <input placeholder="·" 
                               maxlength="1"
                               type="tel"
                               ref="sms_code_3"
                               :class="{'sms_digit_error': code.error}"
                               @keyup="nextCodeDigit(4); testSmsCode()"
                               v-model="code.code_3" />
                        <input placeholder="·" 
                               maxlength="1"
                               type="tel"
                               @keyup="testSmsCode()"
                               ref="sms_code_4"
                               :class="{'sms_digit_error': code.error}"
                               v-model="code.code_4" />
                    </div>
                    <div 
                        :class="{'btn_active': btnActive}"
                        @click.stop="checkSmsCode"
                        class="btn">
                        {{ btn.title }}
                    </div>
                    <div id="timerText" v-if="timer.timerIsWork">
                        Запросить код повторно<br />
                        можно через {{ timer.diffValueString }}
                    </div>
                </div>
            </div>
            <div v-if="page == 3">
                <div class="popup_auth_form_title ta-left">Введите данные</div>
                <div class="popup_auth_body">
                    <div class="form_label_2">Ваше имя</div>
                    <input class="field_input_2" placeholder="" v-model="formUserInfo.first_name" maxlength="80" />
                    <div class="form_label_2">Ваша фамилия</div>
                    <input class="field_input_2" placeholder="" v-model="formUserInfo.last_name" maxlength="80" />
                    <div class="form_label_2">E-mail</div>
                    <input class="field_input_2" placeholder="" v-model="formUserInfo.email" maxlength="80" />
                    <div class="form_label_2">Должность</div>
                    <input class="field_input_2" placeholder="" v-model="formUserInfo.position" maxlength="150"/>
                    <div class="form_label_2">Наименование объекта</div>
                    <input class="field_input_2" placeholder="" v-model="formUserInfo.object_name" maxlength="150" />
                </div>
                <div class="error_form">{{ formUserInfo.error }}</div>
                <div class="btn btn_active" @click.stop="saveUserData">
                   Зарегистрироваться
                </div>
            </div>
            <div v-if="page == 4" id="alert_success_registration">
                <div id="alert_success_registration_icon">
                    <img src="/images/success_registration.svg" />
                </div>
                <div id="alert_success_registration_text_1">
                    Вы успешно<br /> авторизовались!
                </div>
                <div id="alert_success_registration_text_2">
                    Теперь вам доступны все функции<br /> личного кабинета
                </div>
                <div class="btn btn_active" @click.stop="closeForm">
                   На главную
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    import * as global from '../global.js'
    
    export default {
        name: 'PopupAuth',
        emits: ["closeForm", "successfulAuth"],
        data() {
            return {
                
                showContent: false,
                phone: '',
                page: 1,
                okPhone: false,
                btnActive: false,
                code: {
                    code_1: '',
                    code_2: '',
                    code_3: '',
                    code_4: '',
                    error: false,
                },
                btn: {
                   title: 'Подтвердите номер' 
                },
                timer: {
                    timerIsWork: false,
                    objInterval: null,
                    endDateTime: null,
                    diffValueString: '',
                },
                formUserInfo: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    position: '',
                    object_name: '',
                    error: '',
                }
            };
        },
        methods: {
            show() {
                this.showContent = true;
            },
            hide() {
                this.showContent = false;
            },
            closeForm() {
                this.$emit("closeForm");
                this.hide();
            },
            sendSms() {
                this.$bus.emit('showLoading', 'Отправлем смс код...');

                this.$postRequest(this.$config.urlUserSendSms, {
                    phone: this.phone
                })
                .then(() => {
                    this.$bus.emit('hideLoading');

                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            showPage(v) {
                // проверим, если такой номер телефона, есть в системе, и аккаунт активирован, тогда сразу даем авторизацию
                if(v == 2) {
                    this.$bus.emit('showLoading', 'Проверка...');
                    
                    this.$postRequest(this.$config.urlCheckExistPhone, {
                        phone: this.phone
                    })
                    .then((data) => {
                        this.$bus.emit('hideLoading');
                        localStorage.isAuth     = '1';
                        localStorage.User3Id    = data.id;
                        localStorage.User3Token = data.api_token;
                        this.showPage(4);
                        this.$emit("successfulAuth");
                    })
                    .catch(() => {
                        this.$bus.emit('hideLoading');
                        
                        this.page = v;
                        if(v == 2) {
                            this.timerStart();
                            this.sendSms();
                        }
                    });
                }
                else {
                   this.page = v; 
                }
            },
            nextCodeDigit(index) {
                if(index == 2) {
                    this.$refs.sms_code_2.focus();
                }
                else if(index == 3) {
                    this.$refs.sms_code_3.focus();
                }
                else if(index == 4) {
                    this.$refs.sms_code_4.focus();
                }
            },
            testPhone() {
                this.okPhone = global.validatePhone(this.phone);
            },
            testSmsCode() {
                this.btnActive =   this.code.code_1.length == 1
                                && this.code.code_2.length == 1
                                && this.code.code_3.length == 1
                                && this.code.code_4.length == 1;
            },
            timerStart() {
                this.timer.endDateTime = new Date( (new Date()).getTime() + 1.01 * 60000 );
                this.getDiffTime();
                this.timer.timerIsWork = true;
                this.timer.objInterval = setInterval(() => {
                    this.getDiffTime();
                }, 1000);

                setTimeout(() => { this.$refs.sms_code_1.focus() }, 200);
                this.btn.title = 'Подтвердите номер';
                this.code.error = false;
            },
            getDiffTime() {
                let diff = ( this.timer.endDateTime.getTime() - (new Date()).getTime() ) / 1000;

                if( diff <= 0 ) 
                {
                    clearInterval(this.intervalTimer1);
                    this.timer.timerIsWork = false;
                    this.btn.title = 'Запросить код повторно';
                    this.btnActive = true;
                    this.code.code_1 = '';
                    this.code.code_2 = '';
                    this.code.code_3 = '';
                    this.code.code_4 = '';
                    if(this.page == 2) {
                        setTimeout(() => { this.$refs.sms_code_1.focus() }, 200);
                    }
                }
                else 
                {
                    let min = parseInt(diff / 60);
                    let sec = parseInt(diff - min * 60);
                    
                    if(min == 0) {
                        min = '';
                    }
                    else {
                        if (min < 10) min = '0' + min;
                        min += ' ' + global.getCorrectSuffix(parseInt(min), 'минуту', 'минуты', 'минут') + ' : ';
                    }
                    
                    if (sec < 10)   sec = '0' + sec;
                    
                    sec += ' ' + global.getCorrectSuffix(parseInt(sec), 'секунду', 'секунды', 'секунд');

                    this.timer.diffValueString = '' + min + sec;
                }
            },
            checkSmsCode() {
                if(! this.timer.timerIsWork) {
                    this.timerStart();
                    this.sendSms();
                    return;
                }
                
                if(this.btnActive) {
                    this.code.error = false;
                    
                    this.$bus.emit('showLoading', 'Проверка смс кода...');

                    this.$postRequest(this.$config.urlCheckSendSms, {
                        phone: this.phone,
                        code: this.code.code_1 + this.code.code_2 + this.code.code_3 + this.code.code_4
                    })
                    .then((data) => {
                        this.$bus.emit('hideLoading');
                        if(data.activated) {
                            localStorage.isAuth     = '1';
                            localStorage.User3Id    = data.id;
                            localStorage.User3Token = data.api_token;
                            this.$emit("successfulAuth");
                            this.closeForm();
                        }
                        else {
                            this.page = 3;
                        }
                    })
                    .catch(() => {
                        this.$bus.emit('hideLoading');
                        this.code.error = true;
                    });
                }
            },
            saveUserData() {
                this.formUserInfo.error = '';
                let first_name = this.formUserInfo.first_name.trim();
                let last_name = this.formUserInfo.last_name.trim();
                let email = this.formUserInfo.email.trim();
                let position = this.formUserInfo.position.trim();
                let object_name = this.formUserInfo.object_name.trim();
                
                if( first_name.length == '') {
                    this.formUserInfo.error = 'Не указано имя';
                    return;
                }
                if( last_name.length == '') {
                    this.formUserInfo.error = 'Не указана фамилия';
                    return;
                }
                if( ! global.validateEmail(email)) {
                    this.formUserInfo.error = 'Emai указан неверно';
                    return;
                }
                if( position.length == '') {
                    this.formUserInfo.error = 'Не указана должность';
                    return;
                }
                if( object_name.length == '') {
                    this.formUserInfo.error = 'Не указано название объекта';
                    return;
                }
                
                this.$bus.emit('showLoading', 'Проверка смс кода...');

                this.$postRequest(this.$config.urlCompleteRegistration, {
                    phone: this.phone,
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    position: position,
                    object_name: object_name,
                })
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.page = 4;
                    localStorage.isAuth     = '1';
                    localStorage.User3Id    = data.id;
                    localStorage.User3Token = data.api_token;
                    this.$emit("successfulAuth");
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                    this.code.error = true;
                });
            }
        }
    }
</script>

<style scoped>
    .popup_auth {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(150, 150, 150, 0.1);
    }
    .popup_auth_form {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 340px;
        min-height: 394px;
        top: 48%;
        left: 50%;
        border: 1px solid #eee;
        transform: translate(-50%, -50%);
        background: #fefefe;
        padding: 40px 40px;
        border-radius: 10px;
        height: max-content;
        max-height: 600px;
    }
    .popup_auth_formCloseBtn {
        position: absolute;
        display: inline-block;
        top: 22px;
        right: 29px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(/images/close-popup.svg);
        background-size: 20px 20px;
        opacity: 0.7;
        cursor: pointer;
    }
    .popup_auth_formCloseBtn:hover {
        opacity: 1;
    }
    .popup_auth_form_title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #454A4D;
        margin-bottom: 28px;
        text-align: center;
    }
    .form_label {
        margin-bottom: 11px;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: rgba(69, 74, 77, 0.7);
    }
    .form_label_2 {
        margin-bottom: 2px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: rgba(69, 74, 77, 0.5);
    }
    .field_input {
        border: none;
        border-bottom: 1px solid #CCC;
        padding: 11px 2px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #454A4D;
        width: 100%;
        margin-bottom: 30px;
    }
    .field_input_2 {
        border: none;
        border-bottom: 1px solid #CCC;
        padding: 8px 2px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #454A4D;
        width: 100%;
        margin-bottom: 14px;
    }
    .btn {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        background: rgba(32, 148, 255, 0.39);
        border-radius: 5px;
        cursor: default;
    }
    .btn_active {
        background: #2094FF;
        cursor: pointer;
    }
    .btn_active:hover {
        background: #1582e7;
    }
    #form_smsCode {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    #form_smsCode > input {
        background: rgba(32, 148, 255, 0.1);
        border-radius: 5px;
        width: 55px;
        height: 75px;
        font-style: normal;
        font-weight: normal;
        font-size: 35px;
        line-height: 40px;
        text-align: center;
        color: #454A4D;
        border: 1px solid transparent;
        display: inline-block;
        margin-right: 6px;
    }
    #form_smsCode > input:last-child {
        margin-right: 0;
    }
    #popup_auth_form_title_2 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #454A4D;
        margin-bottom: 29px;
    }
    #timerText {
        margin-top: 15px;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: rgba(69, 74, 77, 0.4);
    }
    .ta-left {
        text-align: left;
    }
    .error_form {
        color: red;
        margin: 5px 0 15px 0;
        text-align: center;
        font-size: 12px;
        height: 14px;
    }
    #alert_success_registration {
        display: flex;
        flex-direction: column;
    }
    #alert_success_registration > div {
        text-align: center;
    }
    #alert_success_registration_icon {
        margin-bottom: 25px;
    }
    #alert_success_registration_text_1 {
        font-style: normal;
        font-weight: bold;
        font-size: 23px;
        line-height: 32px;
        text-align: center;
        color: #454A4D;
        margin-bottom: 17px;
    }
    #alert_success_registration_text_2 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #454A4D;
        margin-bottom: 26px;
    }
    .sms_digit_error {
        border-color: red !important;
        color: red !important;
    }
</style>