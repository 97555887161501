import { createRouter, createWebHistory } from 'vue-router'

import Model from '@/components/Model.vue'
import NotFound from '@/components/NotFound.vue'

const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/model/:serialNumber([A-Z]{2}[0-9]{8})',
      name: 'Model',
      component: Model
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
  ]
});

export default router;