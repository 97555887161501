import router from '@/router'
import { toast } from '@/toast.js'

let host_main  = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/' : 'https://api.qrdata.tech/';
let host_api   = host_main + 'api/user/';

export const g_config = {
    urlMain: host_main,
    urlLoadModelInfo: host_api + 'user-load-model-info/',
    urlUserSendSms: host_api + 'user-send-sms',
    urlCheckSendSms: host_api + 'user-check-sms-code',
    urlCompleteRegistration: host_api + 'user-complete-registration',
    urlIncrementDownloadManual: host_api + 'user-increment-download-manual/',
    urlIncrementDownloadTechnicalCertificate: host_api + 'user-increment-download-technical-certificate/',
    urlIncrementDownloadOtherDocs: host_api + 'user-increment-download-other-docs/',
    urlRegisterStatDownloadManualByUser3: host_api + 'register-stat-download-manual-by-user-3/',
    urlCheckSession: host_api + 'check-session',
    urlCheckExistPhone: host_api + 'check-exist-phone',
}

/**
 * Обработка ошибки апи, которую вернул сам сервер, в json ответа
 */
export const apiErrorHandling = (data) => {
    console.log(data);

    if(data.key == "unauthorized")
    { 
        localStorage.mToken = '';
        toast.toast( { type: 'text', value: "Пользователь не авторизован" }, { settings: { duration: 5300 } });
        router.push('/login');
        return;
    }
};

/**
 * Обработка ошибки запроса к апи
 */
const errorResponseHandling = (err, method, url, text, request_data = {}) => {
    
    console.log(err, '-', err.name, '-', err.message, method, url, text, request_data);
    
    let printMessage = "Ошибка обработки запроса";
    
    if (err instanceof TypeError) {
        if(err.message.indexOf("NetworkError") != -1) {
            printMessage = "Ошибка сети";
            toast.toast( { type: 'text', value: printMessage }, { settings: { duration: 5300 } });
        }
    }
    /*else if (err instanceof SyntaxError) {
        
        fetch(g_config.urlSendError, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization': 'Bearer ' + localStorage.token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                type: method,
                url: url,
                data:  navigator.userAgent 
                        + ' \n' + window.location 
                        + ' \n' + JSON.stringify(request_data) 
                        + ' \n' + text
            })
        });
    }*/

    //toast.toast( { type: 'text', value: printMessage }, { settings: { duration: 5300 } });
};

/**
 * Запрос POST
 * 
 * @param {type} url
 * @param {type} request_data
 * @param {type} x_csrf_token
 * @returns {unresolved}
 */
export const g_postRequest = async (url = '', request_data = {}, token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(request_data)
        });
        
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'POST', url, text, request_data);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
 * Запрос POST
 * 
 * @param {type} url
 * @param {type} request_data (new FormData())
 * @param {type} x_csrf_token
 * @returns {unresolved}
 */
export const g_postRequest2 = async (url = '', request_data, token) => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: request_data
        });
    
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'POST', url, text, request_data);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
 * Запрос PUT
 * 
 * @param {type} url
 * @param {type} request_data
 * @param {type} x_csrf_token
 * @returns {unresolved}
 */
export const g_putRequest = async (url = '', request_data = {}, token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(request_data)
        });
    
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'PUT', url, text, request_data);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
 * Запрос GET
 * 
 * @param {type} url
 * @param {type} token
 * @returns {unresolved}
 */
export const g_getRequest = async (url = '', token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
        
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'GET', url, text);
        return Promise.reject({type_error: 'other', data: err});
    }
};


/**
 * Запрос DELETE
 * 
 * @param {type} url
 * @param {type} token
 * @returns {unresolved}
 */
export const g_deleteRequest = async (url = '', token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
    
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'DELETE', url, text);
        return Promise.reject({type_error: 'other', data: err});
    }
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone) => {
    return /\+7\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}/.test(phone);
};

/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
export const humanFileSize = (bytes, si=false, dp=1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si 
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

/**
 * @example getCorrectSuffix(10, "день","дня", "дней")
 */
export const getCorrectSuffix = (num, str1, str2, str3) => {
    var val = num % 100;
    
    if (val > 10 && val < 20)
    {
      return str3;
    }
    else
    {
      val = num % 10;
      if (val == 1)
      { return str1; }	    
      else if (val > 1 && val < 5)
      { return str2; }
      else
      { return str3; }
    }
}