<template>
    <div id="root" v-show="showContent">
        <div id="body_c" v-if="! notFound && ! errorNetwork">
            <div id="body">
                <div v-if="data">
                    <img id="model_logo" src="/images/logo.svg" />
                    <div id="model_name">{{ data.name }}</div>
                    <img id="model_img" :src="$config.urlMain + 'images/models/' + data.index + '/' + data.main_img" />
                    <div id="model_serial_number">Серийный номер:<br />{{ data.serial_number }}</div>
                    <div id="model_serial_manufacturer_name">{{ data.manufacturer_name }}</div>
                    <div class="title">Документы модели</div>
                    <div>
                        <div class="downloadBtn">
                            <span class="btn"
                               @click="incrementDownloadManual"
                               >Руководство по эксплуатации<br/>({{ humanFileSize(data.manual_doc.fileSize) }})</span>
                        </div>
                        <div class="downloadBtn">
                            <template v-if="isAuth">
                                <span class="btn"
                                      @click="incrementDownloadTechnicalCertificate">Технический паспорт<br/>({{ humanFileSize(data.technical_cert.fileSize) }})</span>
                            </template>
                            <template v-else>
                                <span 
                                    @click.stop="openPopupAuth"
                                    class="btn">Технический паспорт<br/>({{ humanFileSize(data.technical_cert.fileSize) }})</span>
                            </template>
                        </div>
                        <div
                                v-for="(item, index) in data.docs"
                                :key="index"
                                class="downloadBtn"
                                >
                            <span 
                               class="btn"
                               @click="incrementDownloadOtherDocs($config.urlMain +'docs/' + data.index + '/' + item.fname)"
                               >
                                {{ item.name }}<br/>
                                {{ humanFileSize(item.fileSize) }}
                            </span>
                        </div>
                    </div>
                    <div class="title" v-if="data.urls.length > 0">Ссылки</div>
                    <div>
                        <div class="link"
                            v-for="(url, index) in data.urls"
                            :key="index"
                            >
                           <a :href="url">{{ url }}</a>
                        </div>
                    </div>
                    <div class="title" v-if="data.description.length > 0">Описание</div>
                    <div class="description">
                        {{ data.description }}
                    </div>
                    <div class="title" v-if="data.guarantee.length > 0">Гарантия</div>
                    <div class="description">
                        {{ data.guarantee }}
                    </div>
                </div>
            </div>
            <div id="footer">
                <img src="/images/logo2.svg" />
                <div>Copyright © 2022 Lockerbox</div>
                <div>Все права защищены.</div>
            </div>
        </div>
        <div v-if="notFound" id="not_found">
            Серийный номер не найден
        </div>
        <div v-if="errorNetwork" id="error_network">
            Ошибка сети
        </div>
    </div>
    <PopupAuth ref="popupAuth" @successfulAuth="isAuth = true"/>
</template>

<script>
    import * as global from '../global.js'
    import PopupAuth from './PopupAuth.vue'
    
    export default {
        name: 'Model',
        components: {
            PopupAuth
        },
        data() {
            return {
                showContent: false,
                notFound: false,
                errorNetwork: false,
                serialNumber: '',
                data: null,
                isAuth: false,
            };
        },
        created() {
            if(localStorage.isAuth && localStorage.User3Id) {
                this.isAuth = localStorage.isAuth;
            }
        },
        mounted() {
            this.initPage();
        },
        methods: {
            initPage() {
                const found_1 = this.$route.params.serialNumber.match(/^[A-Z]{2}\d{8}$/i);

                if( !found_1) {
                    this.$router.push('/');
                }

                this.serialNumber = found_1[0];
                this.loadModelData();
                
                if(localStorage.User3Id && localStorage.User3Token) {
                    // проверка сессии
                    this.$postRequest(this.$config.urlCheckSession, {
                        id: localStorage.User3Id,
                        token: localStorage.User3Token
                    })
                    .catch(() => {
                        this.isAuth             = false;
                        localStorage.isAuth     = '';
                        localStorage.User3Id    = '';
                        localStorage.User3Token = '';
                    });
                }
            },
            openPopupAuth() {
                this.$refs.popupAuth.show(); 
            },
            loadModelData() {
                this.$bus.emit('showLoading', 'Получение данных...');

                this.$getRequest(this.$config.urlLoadModelInfo + this.serialNumber, localStorage.mToken)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.data = data.data;
                    document.title = this.data.name;
                    this.showContent = true;
                })
                .catch((data) => {
                    this.$bus.emit('hideLoading');
                    //console.log(data);
                    if(data.type_error === 'api') {
                        if(data.data.key === 'not found') {
                            this.notFound = true;
                        }
                    }
                    else if(data.type_error === 'other') {
                        if(data.data.message.indexOf('NetworkError') > -1) {
                            this.errorNetwork = true;
                        }
                    }
                    this.showContent = true;
                });
            },
            incrementDownloadManual() {
                this.$getRequest(this.$config.urlIncrementDownloadManual + this.data.index + '/' + this.serialNumber, localStorage.mToken)
                .then(() => {
                    window.location = this.$config.urlMain +'docs/' + this.data.index + '/' + this.data.manual_doc.fname;
                });
                
            },
            incrementDownloadTechnicalCertificate() {
                this.$getRequest(this.$config.urlIncrementDownloadTechnicalCertificate + this.data.index + '/' + this.serialNumber, localStorage.mToken)
                .then(() => {
                    if(localStorage.User3Id && localStorage.User3Token) {
                        
                        this.$bus.emit('showLoading', 'Формирование документа...');
                        
                        this.$getRequest(
                                    this.$config.urlRegisterStatDownloadManualByUser3
                                  + localStorage.User3Id
                                  + '/'
                                  + this.serialNumber
                               , localStorage.mToken
                        )
                        .then(() => {
                            
                            this.$bus.emit('hideLoading');
                            
                            //window.location = this.$config.urlMain +'download-technical-certificate/' + localStorage.User3Token + '/' + this.data.hid;
                            let url = this.$config.urlMain 
                                    + 'download-technical-certificate/'
                                    + localStorage.User3Token 
                                    + '/'
                                    + this.data.hid
                                    + '/'
                                    + this.serialNumber
                            ;
                            
                            var link = document.createElement("a");
                            link.href = url;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            
                            /*fetch(url, {method: 'GET'})
                                .then(function(resp) {
                                    return resp.blob();
                                })
                                .then(function(blob) {
                                    download(blob);
                                });*/
                        });
                    }
                });
            },
            incrementDownloadOtherDocs(url) {
                this.$getRequest(this.$config.urlIncrementDownloadOtherDocs + this.data.index + '/' + this.serialNumber, localStorage.mToken)
                .then(() => {
                    window.location = url;
                });
            },
            humanFileSize(size) {
                return global.humanFileSize(size);
            },
        }
    }
</script>
<style>
    #app {
        background: #FAFAFA;
    }
</style>
<style scoped>
    #body {
        padding: 24px;
        
    }
    #body {
    }
    #model_logo {
        display: inline-block;
        margin-bottom: 20px;
    }
    #model_name {
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 40px;
        color: #454A4D;
        margin-bottom: 20px;
    }
    #model_serial_number {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: rgba(69, 74, 77, 0.7);
        margin-bottom: 20px;
    }
    #model_serial_manufacturer_name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #555;
        margin-bottom: 30px;
    }
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #454A4D;
        margin-top: 20px;
        margin-bottom: 16px;
    }
    .btn {
        width: 280px;
        padding: 5px 15px;
        background: #3399f7;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
    }
    .btn:hover {
        background: #1279d8;
    }
    .downloadBtn {
        margin: 16px 0;
    }
    .link {
        margin: 14px 0;
    }
    .link > a {
        font-size: 15px;
        font-weight: 500;
        color: #2a93f3;
    }
    .description {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 30px;
        max-width: 380px;
        text-align: justify;
    }
    
    #footer {
        padding: 20px;
        background: #1C1B1B;
    }
    #footer > img {
        display: inline-block;
        margin-bottom: 20px;
    }
    #footer > div {
        color: #FFFFFF;
        opacity: 0.4;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 23px;
    }
    #not_found, #error_network {
        padding: 30px;
        font-size: 16px;
        font-weight: bold;
    }
    #model_img {
        max-width: 270px;
        max-height: 270px;
        display: block;
        margin-bottom: 15px;
    }
</style>
